import React from "react";
import RatePicker from "../rate-picker/RatePicker";
function App() {

    return (
        <div>
            <RatePicker/>
        </div>
    )
}

export default App